import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text, Input } from "../components/Core";

import PageWrapper from "../components/PageWrapper";
import { device } from "../utils";

const FormStyled = styled.form``;

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  @media ${device.lg} {
    padding-left: 140px;
    padding-right: 150px;
  }
  @media ${device.xl} {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

const Contact2 = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Section bg="dark">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <div className="banner-content">
                  <Title variant="hero" color="light">Contact us</Title>
                  <Text color="light">
                    If you're interested in investing in one of our projects, 
                    <br className="d-none d-md-block" /> or working for us, please contact us{" "}
                  </Text>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center mt-5 pt-lg-5">
              <Col xl="12">
                <WidgetWrapper>
                  <Row>
                    <Col md="6" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          Email
                        </Title>
                        <Text>info@blockriver.tech</Text>
                        {/* <Text>support@mail.com</Text> */}
                      </Box>
                    </Col>
                    <Col md="6" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          Office
                        </Title>
                        <Text>XinYi District</Text>
                        <Text>Taipei, Taiwan</Text>
                      </Box>
                    </Col>
                  </Row>
                </WidgetWrapper>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Contact2;
